import { isProdEnv } from "@/config";
import { CardBox, Section, Grid, Video, Typography, Button, AiBot } from "@/ui";

import useStyles from "./styles";

const BannerAiPlanner = () => {
  const classes = useStyles();
  const videoId = isProdEnv
    ? "site-prod/misc/videos/atlasguru-ai-itinerary-teaser_tjo1or"
    : "site-build-dev/misc/videos/atlasguru-ai-itinerary-teaser_fw5del";
  const videoPoster = isProdEnv
    ? "https://res.cloudinary.com/atlasguruprod/image/upload/c_thumb,w_600/v1/site-prod/misc/videos/atlasguru-ai-itinerary-teaser-cover_nw7qmt"
    : "https://res.cloudinary.com/atlasguru/image/upload/c_thumb,w_600/v1/site-build-dev/misc/videos/atlasguru-ai-itinerary-teaser-cover_wnyaeb";

  return (
    <Section className={classes.root}>
      <Grid item xs={12}>
        <CardBox rounded classes={{ content: classes.cardContent }}>
          <div className={classes.content}>
            <header className={classes.contentHeader}>
              <Typography component="h2" color="primary">
                <strong>
                  {"Plan your dream trip "}
                  <AiBot
                    classes={{
                      root: classes.aiBannerBot,
                      img: classes.aiBannerBotImg,
                    }}
                  />
                  <br />
                  in minutes with Itinerary AI
                </strong>
              </Typography>
            </header>

            <figure className={classes.contentFigure}>
              <div className={classes.videoWrapper}>
                <Video
                  className={classes.video}
                  cloudId={videoId}
                  poster={videoPoster}
                  transformations={{
                    quality: "auto:best",
                    crop: "scale",
                    width: 1280,
                    height: 720,
                  }}
                />
              </div>
            </figure>

            <div className={classes.contentInfo}>
              <Typography variant="subtitle2" component="p" color="primary">
                Travel stories by real humans power AtlasGuru&apos;s AI engine,
                so you can plan the perfect trip instantly for FREE.
                {/* <Link href="#">Learn more</Link> */}
              </Typography>

              <Button
                href="/ai-itinerary-planner/"
                className={classes.button}
                variant="contained"
                color="secondary"
                rounded
              >
                Start Planning
              </Button>
            </div>
          </div>
        </CardBox>
      </Grid>
    </Section>
  );
};

export default BannerAiPlanner;
